import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import RichText from "../components/rich-text"
import HeadElements from "../components/head_attributes"
import GraphComments from '../components/GraphComments'



const BlogPageTemplate = ({ data }) => {
  const {
    swapi: {
      blogpage: { title, seoTitle, searchDescription,intro, url,slug,image, body },
    },
  } = data
  
  const siteRootAddress = "https://izarada.com"
  const disqusConfig = {
    url:siteRootAddress+url,
    identifier: slug,
    title:title
  }
  const rewriteUrl = (url) =>{
    
    const baseUrl = "https://photos.izarada.com/file/internet-zarada/internet-zarada/izarada-media/"

    return baseUrl + url
  }

  const headAtributes = {"seoTitle":seoTitle, "seoDescription":searchDescription, "url":url,"image":rewriteUrl(image.file)}

  return (
    <Layout>
      <HeadElements headAtributes={headAtributes} />
      <div className="w-full h-64 lg:h-96 relative bg-black">
        <img
          src={rewriteUrl( image.file)}
          className="opacity-80 object-cover h-full w-full absolute md:h-full"
          alt={image.title}
        ></img>
        {/* <div className="p-8 relative lg:left-0 lg:top-1/3 lg:w-2/5"></div> */}
      </div>


    
      <div className="sm:container mx-auto text-center">
        <h1 className="text-primary font-bold text-4xl p-8">{title}</h1>
        <p className="text-gray-500  mx-auto max-w-lg px-8 pt-2  text-center pb-6 text-lg">
          {intro}
        </p>
        <div className="w-24 h-1 bg-primary inline-block"></div>
      </div>

      <div className="container mx-auto my-12 text-xl text-gray-800 max-w-4xl">
        <RichText richText={body}></RichText>
        <GraphComments />
        

      </div>

 

     
    </Layout>
  )
}

// export const query = graphql`
// query getBlogPage($slug:String) {
//     swapi {
//         blogpage(slug: $slug) {
//           url
//           seoTitle
//           seoDescription
//           body {
//             blockType
//             field
//             rawValue
//           }
//         }
//       }
//   }
// `

export const query = graphql`
query getBlogPage($slug: String) {
  swapi {
    blogpage(slug: $slug) {
      title
      seoTitle
      searchDescription
      intro
      url
      slug
      image {
        file
        title
      }
      body {
        field
        ... on SWAPI_CustomImageBlock {
          id
          attribution
          caption
          slika {
            file
            sizes
            renditions {
              image {
                id
                title
              }
            }
          }
          blockType
        }
        ... on SWAPI_BlockQuote {
          text
          id
          rawValue
          field
          blockType
          attributeName
        }
        ... on SWAPI_RichTextBlock {
          id
          value
          rawValue
          blockType
          field
        }
        ... on SWAPI_ImageBlock {
          id
          alignment
          attribution
          blockType
          caption
          field
          image {
            
            title
            
            file
          }
        }
        ... on SWAPI_WideImage {
          id
          blockType
          field
          image {
            file
            title
            
          }
        }
        ... on SWAPI_PullQuoteImageBlock {
          id
          field
          rawValue
          blockType
        }
        ... on SWAPI_BustoutBlock {
          id
          rawValue
          field
          blockType
        }
        ... on SWAPI_EmbedBlock {
          id
          value
          url
          rawEmbed
          field
          embed
          blockType
        }
        ... on SWAPI_CharBlock {
          id
          blockType
          field
          rawValue
          value
        }
        ... on SWAPI_TextBlock {
          id
          blockType
          field
          value
          rawValue
        }
        ... on SWAPI_RawHTMLBlock {
          id
          value
          rawValue
          field
          blockType
        }
      }
      pageType
    }
  }
}

`
export default BlogPageTemplate
