import React from "react"
import "./rich-text.css"

const rich_text = props => {

  const rewriteUrl = (url) =>{
    const baseUrl = "https://photos.izarada.com/file/internet-zarada/internet-zarada/izarada-media/"

    return baseUrl + url
  }

  return (
    <>
      {props.richText.map(element => {
        if (element.field === "rich_text") {
          return (
            <div>
              <div
                className="rich-text-block"
                dangerouslySetInnerHTML={{ __html: element.rawValue }}
              />
            </div>
          )
        } else if (element.field === "block_quote") {
          return (
            <blockquote className="simple-blockquote text-lg md:text-xl italic border-primary border-solid border-l-8">
              {element.text}

              <span>{element.attributeName}</span>
            </blockquote>
          )
        } else if (element.blockType === "CustomImageBlock") {
          return (
            <div>
              <img src={rewriteUrl( element.slika.file)}></img>
            </div>
          )
        } else if (element.field === "h2") {
          return <h2>{element.value}</h2>
        } else if (element.field === "h3") {
          return <h3>{element.value}</h3>
        } else if (element.field === "h4") {
          return <h4>{element.value}</h4>
        } else if (element.field === "intro") {
          return <p>{element.value}</p>
        } else if (element.field === "paragraph") {
          return (
            <div>
              <div
                className="rich-text-block paragraph-block"
                dangerouslySetInnerHTML={{ __html: element.rawValue }}
              />
            </div>
          )
        } else if (element.field === "raw_html") {
          // console.log(element)
          return (
            <div>
              <div
                className="raw-text-block rich-text-block"
                dangerouslySetInnerHTML={{ __html: element.rawValue }}
              />
            </div>
          )
        } else if (element.field === "aligned_image") {
          return (
            <div className={`aligned-image relative content-center	${element.alignment}`}>
              
          
              
              <img src={rewriteUrl( element.image.file)} alt={element.image.title}></img>
              {element.caption?<span className="mx-auto block text-center text-xs italic">{element.caption}</span>:<></>}

              {element.attribution?<span className=" absolute block left-0 top-0 mr-2 mb-2 p-1 bg-indigo-600 bg-opacity-20 text-xs italic text-white">{element.attribution}</span>:<></>}
              
              
            </div>
          )
        } else if (element.field === "wide_image") {
          return <img src={rewriteUrl( element.image.file)} alt={element.image.title}></img>
        } else if (element.field === "embed_block") {
          return (
            <div
              className="embed-block rich-text-block"
              dangerouslySetInnerHTML={{ __html: element.embed }}
            ></div>
          )
        } else if (element.field === "heading"){
          return(
            <div>
              <div
                className="rich-text-block"
                dangerouslySetInnerHTML={{ __html: element.rawValue }}
              />
            </div>
          )
        }
         else {
          return <></>
        }
      })}
    </>
  )
}

export default rich_text
