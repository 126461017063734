import React, {useEffect} from 'react'
const COMMENT_DIV_ID = "graphcomment"

const GraphComments = () =>{
    useEffect(() => {
        window.gc_params = {
            graphcomment_id: 'izarada-id',
            fixed_header_height: 0,
          };

        const script = document.createElement('script');
        script.src = 'https://graphcomment.com/js/integration.js?' + Date.now();
        script.async = true;

        const comments = document.getElementById(COMMENT_DIV_ID);
        if (comments) comments.appendChild(script);

        // This function will get called when the component unmounts
        // To make sure we don't end up with multiple instances of the comments component
        return () => {
            const comments = document.getElementById(COMMENT_DIV_ID);
            if (comments) comments.innerHTML = '';
        };
    }, []);

    return <div id={COMMENT_DIV_ID} />
}

export default GraphComments;
